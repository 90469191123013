
import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Kuldeep Singh",
  title: "Hi all, I'm Kuldeep Singh",
  subTitle: emoji(
    "🚀 Results-oriented Full-Stack Developer with a proven track record in dynamic web applications. Skilled in strategic project management, team leadership, and modern web technologies. Seeking to leverage cloud computing and DevOps expertise. 🌟"
  ),
  resumeLink:
    "https://docs.google.com/document/d/1KP7ci2---et6laGl2HUWaCtgETLyHSxN7lJoM4oV1Is/edit?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/kuldeep059",
  linkedin: "https://www.linkedin.com/in/kuldeep-singh-019b40226",
  gmail: "kuldeep__singh@outlook.com",
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "Full-Stack Developer specializing in Cloud Computing and DevOps, with a proven track record in project management and team leadership, delivering dynamic web applications and leveraging technical expertise to create innovative solutions that drive development excellence. Currently learning AI and ML.",
  skills: [
    emoji(
      "⚡ Full-Stack Cloud Developer with DevOps expertise, actively expanding into AI/ML."

    ),
    emoji(
      "⚡Proven project leadership and hands-on experience in cloud technologies, driven by a passion for innovation."
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "bootstrap",
      fontAwesomeClassname: "fab fa-bootstrap"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "git",
      fontAwesomeClassname: "fab fa-git"
    },
    {
      skillName: "mongo-db",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "azure",
      fontAwesomeClassname: "fab fa-microsoft"
    },
    {
      skillName: "devops",
      fontAwesomeClassname: "fas fa-cloud"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Amity University Online",
      logo: require("./assets/images/amity online.jpg"),
      subHeader: "Master of Computer Applications (MCA)",
      duration: "January 2024 - December 2025",
      desc: "Specialization in A.I and Machine Learning",
    },
    {
      schoolName: "SGT University",
      logo: require("./assets/images/logo sgt.jpg"),
      subHeader: "Bachelor of Computer Applications (BCA)",
      duration: "June 2020 - August 2023",
      desc: "Specialization in Cloud Computing",
      // descBullets: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit"]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    },
    {
      Stack: "Cloud Technologies",
      progressPercentage: "70%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Full-Stack Developer",
      company: "SGN OFFICE AUTOMATION",
      companylogo: require("./assets/images/logo-trans.png"),
      date: "August 2023 – February 2024",
      descBullets: [
        "Constructed and Provided Solutions: Created and maintained websites and School Management ERP Software furnishing HTML, CSS, Bootstrap, ReactJS, JavaScript, PHP, and Laravel 9, delivering end-products to customers.",
        "Customer Interaction: Engaged with customers to understand and meet web application requirements.",
        "Team Management: Led a team of 4 members to construct WordPress sites, ensuring the project meets client specifications."
      ]
    },
    {
      role: "Cloud Computing Intern",
      company: "Corizo",
      companylogo: require("./assets/images/corizo.png"),
      date: "January 2023 – March 2023",
      descBullets: [
        "Cloud Collaboration: Collaborated on major and minor projects operating AWS and Microsoft Azure, including deploying websites for public accessibility and building storage AWS buckets.",
        "Chat-Bot Development: Devised a chat-bot using Amazon Lex.",
        "Virtual Machine Deployment: Deployed virtual machines with various operating systems (Mac OS, Linux, Windows) supplementing Vagrant."
      ]
    },
    {
      role: "Front-End Developer Intern",
      company: "Hubple",
      companylogo: require("./assets/images/hub3.png"),
      date: "September 2022 – November 2022",
      descBullets: [
        "Web Development: Developed and implemented intuitive GUI interfaces for 10+ web pages applying HTML, CSS, JavaScript, and Bootstrap, increasing user engagement by 40% and decreasing customer support inquiries by 25%.",
        "Bootstrap Implementation: Enhanced websites using Bootstrap, demonstrating proficiency in modern web development techniques.",
        "Cross-Functional Collaboration: Collaborated with back-end developers, product managers, web designers, and testing teams."
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "false", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on


const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS, COMPANIES AND SCHOOLS THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image: require("./assets/images/aps.png"),
      projectName: "Adarsh Public School- Rohini",
      projectDesc: "Created and administered the Adarsh Public School website featuring a dynamic admission form with automated email submission of details.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://apsrohini.com"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/logo-delight.png"),
      projectName: "Delight Courier Cargo",
      projectDesc: "Developed and managed the Delight Courier Cargo website, featuring a working contact form and integration with various courier tracking sites for hassle-free package tracking",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://delightcouriercargo.in"
        }
      ]
    },
    {
      image: require("./assets/images/sai-logo.png"),
      projectName: "Sai Print Solution",
      projectDesc: "Developed and managed the Sai Print Solution website, featuring a working contact form and popups.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://saiprintsolution.com"
        }
        //  you can add extra buttons here.
      ]
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Indigo Hack to Hire Hackathon",
      subtitle:
        "Developed a real-time flight tracking web application using React, Node.js, MongoDB, and Firebase. The application leveraged external APIs to provide users with up-to-date flight information, presented in an intuitive and interactive interface. This project was recognized among the top 200 entries in a hackathon, showcasing its innovation and technical execution.",
      image: require("./assets/images/IndiGo-Logo.jpg"),
      imageAlt: "Google Code-In Logo",
      footerLink: [
        {
          name: "Project Repository",
          url: "https://github.com/kuldeep059/Case-study-Live-flight-"
        }
      ]
    },
    {
      title: "Introduction to Data Science Certification by Cisco",
      subtitle:
        "Succesfully completed Certification program in Data Science by Cisco.",
      image: require("./assets/images/Cisco.png"),
      imageAlt: "Cisco Badge",
      footerLink: [
        {
          name: "View Badge and Verify",
          url: "https://www.credly.com/badges/b83f0dca-4ec8-469b-be70-2c9210c867ee/linked_in_profile"
        }
      ]
    },

    {
      title: "AWSome day Online conference conducted by Amazon",
      subtitle: "Attended AWS Online Conference in which we were briefed about AWS Console and its various techs and got rewarded with free AWS credits.",
      image: require("./assets/images/amazon.png"),
      imageAlt: "PWA Logo",
      footerLink: [
        {name: "Certificate of Attendance", url: "https://drive.google.com/file/d/1BkxYztZQBmhYv5ghgBFKn1ny1wuCdrE-/view?usp=sharing"},
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Resume Section
const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume",

  // Please Provide with Your Podcast embeded Link
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+91-8810587776",
  email_address: "kuldeep__singh@outlook.com"
};


const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  contactInfo,
  isHireable,
  resumeSection
};
